import React from "react";
import GropoolNoteImage from "../../../../assets/gropoolNoteImage.svg";
import GropoolSuccessImage from "../../../../assets/gropoolsuccessImage.svg";
import { useNavigate } from "react-router-dom";

const CreatedNewGropoolSuccessfullyPopup = () => {
  const navigate = useNavigate();
  return (
    <div className="mb-8 px-2 sm:px-6">
      <div className="mx-auto mt-8 flex max-w-md flex-shrink-0 flex-col items-center justify-center">
        <img
          src={GropoolSuccessImage}
          alt="img"
          className="h-24 w-auto flex-shrink-0"
        />
        <h2 className="mt-1 text-center text-lg font-semibold text-gray-700">
          Your Share Group for Cattle Has Been Created!
        </h2>
        <p className="mt-2 text-center text-xs font-medium text-gray-600">
          You've successfully created a share group. Your slots are confirmed,
          and you're now ready to invite others to join. Track the progress from
          your Plans Page"
        </p>
      </div>
      <div className="mx-auto mt-4 max-w-[220px]">
        <button
          onClick={() => {
            navigate("/gropool");
            window.location.reload();
          }}
          className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-10 py-3.5 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 xsm:text-sm"
        >
          Go to Plans Page
        </button>
      </div>
      <div className="mx-auto mt-8 flex max-w-md flex-col rounded-lg border-[1px] border-[#DFE5D1] p-4 shadow-xl shadow-[#F6F6F5]">
        <h3 className="text-center font-semibold text-gray-800">
          Share Group Info
        </h3>
        <p className="mx-auto mt-1 max-w-sm text-center text-xs font-normal text-gray-600">
          To speed up the process, share the group link with friends or others
          who might be interested in joining{" "}
        </p>
        <div className="mx-auto mt-4 max-w-xs">
          <button className="w-full transform cursor-pointer rounded-md border-[1px] border-[#8DAA6A] bg-[#F6FBEE] px-10 py-3 text-sm font-semibold text-olivine-600 transition-all  duration-200 focus:outline-none">
            Copy link &invite new Buyers
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatedNewGropoolSuccessfullyPopup;
