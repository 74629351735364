import React, { useCallback, useEffect, useRef, useState } from "react";
import Container from "../my_container/Container";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
import AvailableSlotsComponent from "./AvailableSlotsComponent";
import GropoolFruitImage from "../../../assets/gropoolFruitImage.png";
import GropoolVegetableImage from "../../../assets/gropoolVegetableImage.png";
import GropoolDisclosureComponent from "./GropoolDisclosureComponent";
import { FaPlus } from "react-icons/fa";
import CreateNewGropool from "./create_gropool/CreateNewGropool";
import SelectSlotPurchaseOptions from "./create_gropool/SelectSlotPurchaseOptions";
import { useDispatch, useSelector } from "react-redux";
import useEmblaCarousel from "embla-carousel-react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import {
  fetchFAQ,
  fetchHowItWorks,
  fetchProducts,
  getAllGiftReceipients,
  getAllProducts,
} from "../../../store/actions";
import { authService } from "../../../services/authService";
import PurchaseSummaryPopup from "./PurchaseSummaryPopup";
import PaymentCheckoutPopup from "./create_gropool/PaymentCheckoutPopup";
import CreatedNewGropoolSuccessfullyPopup from "./create_gropool/CreatedNewGropoolSuccessfullyPopup";
import InputRecipientDetailsPopupGift from "./create_gropool/InputReceipientDetailsPopupGift";

// const groupLists = [
//   "All Groups",
//   "Rice Groups",
//   "Cow Groups",
//   "Goat Groups",
//   "Beans Groups",
// ];

const groupLists = [
  {
    group_id: "0",
    group_name: "All Groups",
    available_item_sharing: 13,
  },
  {
    group_id: "1805109000006222033",
    group_name: "Rice",
    available_item_sharing: 13,
  },
];

const GropoolPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gropoolRef = useRef();
  const [selected, setSelected] = useState("0");
  const [openGropoolModal, setOpenGropoolModal] = useState(false);
  const [openSelectSlotModal, setOpenSelectSlotModal] = useState(false);
  const [openPaymentSummaryModal, setOpenPaymentSummaryModal] = useState(false);
  const [openPaymentSuccessfulModal, setOpenPaymentSuccessfulModal] =
    useState(false);
  const [openPurchaseSummaryModal, setOpenPurchaseSummaryModal] =
    useState(false);
  const [openRecipientDetailsModal, setOpenRecipientDetailsModal] =
    useState(false);
  const [fetchedHowItWorks, setFetchedHowItWorks] = useState([]);
  const [fetchedGropoolProducts, setFetchedGropoolProducts] = useState({});
  const [fetchedSharingGroup, setFetchedSharingGroup] = useState([]);
  const [fetchedGropoolProductsAll, setFetchedGropoolProductsAll] = useState(
    []
  );
  const [isGift, setIsGift] = useState(false);
  const [fetchedExistingAddress, setFetchedExistingAddress] = useState([]);
  const [purchasingFor, setPurchasingFor] = useState("");
  const [fetchedFAQ, setFetchedFAQ] = useState([]);
  const [fetchedCreateInfo, setFetchedCreateInfo] = useState({});

  const { allProducts } = useSelector((state) => state.productShare);

  const customerId = authService.getCurrentUserCustomerId();
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });

  useEffect(() => {
    if (!fetchedHowItWorks?.length) {
      handleFetchHowItWorks();
    }

    if (!fetchedFAQ?.length) {
      handleFaq();
    }
  }, [fetchedHowItWorks, fetchedFAQ, customerId]);

  useEffect(() => {
    if (!allProducts?.product_sharing_info?.length) {
      handleProductSharingAll();
      handleFetchSavedDelivery();
    }

    if (
      allProducts?.product_sharing_info?.length &&
      allProducts?.product_sharing_group?.length
    ) {
      setFetchedGropoolProductsAll(allProducts?.product_sharing_info);
      setFetchedSharingGroup(allProducts?.product_sharing_group);
    }
  }, [allProducts]);

  useEffect(() => {
    if (customerId) {
      const id = customerId ? customerId : 0;
      handleGropoolProducts(id);
    }
  }, []);

  const handleFetchSavedDelivery = async () => {
    try {
      dispatch(getAllGiftReceipients(customerId))
        .unwrap()
        .then((res) => {
          setFetchedExistingAddress(res);
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  const handleFetchHowItWorks = async () => {
    try {
      dispatch(fetchHowItWorks())
        .unwrap()
        .then((res) => {
          if (!res?.working_flow) {
            setFetchedHowItWorks([]);
          }

          setFetchedHowItWorks(res?.working_flow);
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  const handleFaq = async () => {
    try {
      dispatch(fetchFAQ())
        .unwrap()
        .then((res) => {
          if (!res?.faq_info) {
            setFetchedFAQ([]);
          }

          setFetchedFAQ(res?.faq_info);
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  const handleGropoolProducts = async (id) => {
    try {
      dispatch(fetchProducts(id))
        .unwrap()
        .then((res) => {
          setFetchedGropoolProducts(res);
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  const handleProductSharingAll = async () => {
    try {
      dispatch(getAllProducts())
        .unwrap()
        .then((res) => {
          setFetchedGropoolProductsAll(res);
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const displayTab = () => {
    if (selected === "0") {
      return <AllGroupComponent products={allProducts?.product_sharing_info} />;
    } else {
      const groupNameShowing = fetchedSharingGroup
        ?.filter((id) => id?.group_id === selected)
        .pop();
      const riceProducts = allProducts?.product_sharing_info?.length
        ? allProducts?.product_sharing_info?.filter(
            (prod) => prod?.group_id === groupNameShowing?.group_id
          )
        : [];
      return <AllGroupComponent products={riceProducts} />;
    }
  };

  const AllGroupComponent = ({ products }) => {
    return (
      <div ref={gropoolRef} className="mt-10 grid gap-4 grid-auto-fit-md">
        <div
          onClick={() => setOpenGropoolModal(true)}
          className="min-w-80 w-full cursor-pointer rounded-xl border-[1px] border-gray-200 bg-[#F9F9F9] p-4 hover:border-gray-300"
        >
          <div className="mt-3 flex items-center justify-between">
            <div className="w-fit flex-shrink-0 rounded-full bg-[#558223] p-2">
              <FaPlus className="h-5 w-5 text-white" />
            </div>
            <div>
              <BiArrowBack className="h-5 w-5 rotate-180 text-gray-600" />
            </div>
          </div>
          <div className="mt-4">
            <p className="text-base font-medium text-gray-600">
              Can’t Find a suitable group?
            </p>
            <p className="mt-1 text-xl font-semibold text-[#558223]">
              Create a New Group now
            </p>
          </div>
        </div>
        {products && products?.length
          ? products.map((availableSlotList, index) => {
              return (
                <AvailableSlotsComponent
                  key={index}
                  category={availableSlotList.group_name}
                  name={availableSlotList.item_name}
                  image={availableSlotList.item_image_url}
                  ref_no={availableSlotList.ref_no}
                  slotPrice={availableSlotList.slot_price}
                  slotDate={availableSlotList.created_date}
                  slot_number={availableSlotList.slot_number}
                  total_purchased_slot={availableSlotList.total_purchased_slot}
                />
              );
            })
          : null}
      </div>
    );
  };

  return (
    <>
      <SignedInNavbarImproved />
      <div className="bg-white">
        <Container className="mb-40">
          <div className="mt-10 flex flex-row items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="rounded-full p-2 hover:bg-gray-200 active:bg-gray-100">
                <BiArrowBack
                  onClick={() => navigate(-1)}
                  className="h-5 w-5 cursor-pointer text-gray-600"
                />
              </div>
              <h2 className="text-xl font-bold text-gray-700 sm:text-2xl">
                GroPool
              </h2>
            </div>
            <Link to="/gropool/history">
              <button className="border-b-[1.5px] border-b-white text-base font-semibold text-[#73964C] hover:border-b-[#73964C]">
                GroPool History
              </button>
            </Link>
          </div>
          <div className="mt-8 rounded-xl border-[1px] border-gray-200 px-6 py-8 shadow-xl shadow-[#F6F6F5]">
            <h3 className="text-base font-bold text-gray-600 sm:text-lg">
              Get More by Sharing!
            </h3>
            <p className="mt-1 text-sm font-normal text-gray-500">
              Team up with others to buy large or high-value products at a
              fraction of the cost. Whether it's purchasing a whole cow or a
              bulk package of products, now you can easily split the cost and
              enjoy your portion without the hefty price tag!
            </p>

            <button
              onClick={() =>
                gropoolRef.current?.scrollIntoView({ behavior: "smooth" })
              }
              className="mt-4 rounded-md border-[1px] border-[#558223]/40 bg-[#F6FBEE] px-5 py-3 text-sm font-medium text-[#558223] transition-all duration-300 hover:border-[#558223]/80"
            >
              See GroPool Groups
            </button>
          </div>
          <div className="mt-10">
            <Tab.Group>
              {/*   Tab section header */}
              <Tab.List className="flex w-fit flex-row flex-wrap items-center text-sm">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-100 px-4 py-2 text-olivine-500 outline-none sm:px-10"
                          : "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-2 text-gray-500 sm:px-10"
                      }
                    >
                      <p className="cursor-pointer font-semibold">
                        Available Slots
                      </p>
                    </button>
                  )}
                </Tab>

                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-100 px-4 py-2 text-olivine-500 outline-none sm:px-10"
                          : "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-2 text-gray-500 sm:px-10"
                      }
                    >
                      <p className="cursor-pointer font-semibold">
                        How it Works
                      </p>
                    </button>
                  )}
                </Tab>

                <Tab as={Fragment}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected
                          ? "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-100 px-4 py-2 text-olivine-500  outline-none sm:px-10"
                          : "mt-3 w-fit border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-2 text-gray-500 sm:px-10"
                      }
                    >
                      <p className="cursor-pointer font-semibold">
                        Frequently Asked Questions
                      </p>
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel>
                  <div className="mt-8 flex items-center justify-between overflow-hidden @container">
                    <button
                      onClick={scrollPrev}
                      className="mr-1 w-fit rounded-full border-[1px] border-gray-200 p-1 text-gray-700 hover:bg-gray-200 active:bg-gray-100 "
                    >
                      <MdOutlineKeyboardArrowRight className="h-6 w-6 rotate-180" />
                    </button>
                    <div className="flex-1 overflow-hidden" ref={emblaRef}>
                      <div className="flex">
                        {fetchedSharingGroup?.length
                          ? fetchedSharingGroup.map(
                              (groupList, groupList_index) => {
                                return (
                                  <div className="w-fit flex-shrink-0 flex-grow-0 py-2">
                                    <div
                                      onClick={() =>
                                        setSelected(groupList?.group_id)
                                      }
                                      key={groupList_index}
                                      className={`mr-4 cursor-pointer rounded-md  px-6 py-2.5 text-sm font-medium ${
                                        selected == groupList?.group_id
                                          ? "bg-[#558223] text-white"
                                          : "bg-[#E0E3DC] text-gray-700 transition-all duration-200 hover:bg-[#E0E3DC]/70"
                                      }`}
                                    >
                                      <p>{groupList?.group_name}</p>
                                    </div>
                                  </div>
                                );
                              }
                            )
                          : null}
                      </div>
                    </div>
                    <button
                      onClick={scrollNext}
                      className="ml-1 w-fit rounded-full border-[1px] border-gray-200 p-1 text-gray-700 hover:bg-gray-200 active:bg-gray-100 "
                    >
                      <MdOutlineKeyboardArrowRight className="h-6 w-6" />
                    </button>
                  </div>
                  <div className="">{displayTab()}</div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="mt-10 max-w-5xl space-y-6">
                    {fetchedHowItWorks && fetchedHowItWorks?.length
                      ? fetchedHowItWorks?.map((hiw, idx) => (
                          <div
                            key={idx}
                            className="mt-3 flex w-full flex-shrink-0 flex-col rounded-lg border-[1px] border-[#558223]/10 bg-[#E8F3D4] p-4 sm:flex-row sm:p-6"
                          >
                            <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-[#558223]">
                              <p className="text-sm font-semibold text-white">
                                {hiw?.sharing_step}
                              </p>
                            </div>
                            <div className="mt-1 sm:ml-3 sm:mt-0">
                              <h3 className="text-lg font-bold text-gray-600">
                                {hiw?.sharing_title}
                              </h3>
                              <p className="mt-1 text-sm font-normal text-gray-600">
                                {hiw?.sharing_description}
                              </p>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="mt-10 space-y-6">
                    {fetchedFAQ && fetchedFAQ.length
                      ? fetchedFAQ?.map((faq) => (
                          <GropoolDisclosureComponent
                            question={faq?.question}
                            answer1={faq?.answer}
                          />
                        ))
                      : null}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
          <>
            <Transition appear show={openGropoolModal} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50"
                onClose={() => setOpenGropoolModal(false)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                        <div className="scrollbar-thumb-[#1a3517] scrollbar-track-[#265426] h-full max-h-[525px] overflow-y-scroll p-2 scrollbar-thin">
                          <div
                            onClick={() =>
                              setOpenGropoolModal(!openGropoolModal)
                            }
                            className="fixed right-4 top-2 w-fit cursor-pointer rounded-full bg-[#558223] p-1.5 text-white"
                          >
                            <IoClose className="h-5 w-5" />
                          </div>

                          <CreateNewGropool
                            setOpenSelectSlotModal={setOpenSelectSlotModal}
                            setOpenGropoolModal={setOpenGropoolModal}
                            openGropoolModal={openGropoolModal}
                            products={fetchedGropoolProducts}
                            setFetchedCreateInfo={setFetchedCreateInfo}
                          />
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>

            <Transition appear show={openSelectSlotModal} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50"
                onClose={() => setOpenSelectSlotModal(false)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                        <div className="scrollbar-thumb-[#1a3517] scrollbar-track-[#265426] h-full max-h-[525px] overflow-y-scroll p-2 scrollbar-thin">
                          <div
                            onClick={() =>
                              setOpenSelectSlotModal(!openSelectSlotModal)
                            }
                            className="fixed right-4 top-2 w-fit cursor-pointer rounded-full bg-[#558223] p-1.5 text-white"
                          >
                            <IoClose className="h-5 w-5" />
                          </div>
                          <SelectSlotPurchaseOptions
                            fetchedCreateInfo={fetchedCreateInfo}
                            setOpenGropoolModal={setOpenGropoolModal}
                            setFetchedCreateInfo={setFetchedCreateInfo}
                            setOpenSelectSlotModal={setOpenSelectSlotModal}
                            setOpenPurchaseSummaryModal={
                              setOpenPurchaseSummaryModal
                            }
                            setOpenRecipientDetailsModal={
                              setOpenRecipientDetailsModal
                            }
                            setPurchasingFor={setPurchasingFor}
                            purchasingFor={purchasingFor}
                            setIsGift={setIsGift}
                          />
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>

            <Transition appear show={openPurchaseSummaryModal} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50"
                onClose={() => setOpenPurchaseSummaryModal(false)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                        <div className="scrollbar-thumb-[#1a3517] scrollbar-track-[#265426] h-full max-h-[525px] overflow-y-scroll p-2 scrollbar-thin">
                          <div
                            onClick={() =>
                              setOpenPurchaseSummaryModal(
                                !openPurchaseSummaryModal
                              )
                            }
                            className="fixed right-4 top-2 w-fit cursor-pointer rounded-full bg-[#558223] p-1.5 text-white"
                          >
                            <IoClose className="h-5 w-5" />
                          </div>
                          <PurchaseSummaryPopup
                            fetchedCreateInfo={fetchedCreateInfo}
                            setOpenPurchaseSummaryModal={
                              setOpenPurchaseSummaryModal
                            }
                            setOpenPaymentSummaryModal={
                              setOpenPaymentSummaryModal
                            }
                            setOpenSelectSlotModal={setOpenSelectSlotModal}
                            isUpdate={false}
                            isGift={isGift}
                          />
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>

            <Transition appear show={openPaymentSummaryModal} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50"
                onClose={() => setOpenPaymentSummaryModal(false)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                        <div className="scrollbar-thumb-[#1a3517] scrollbar-track-[#265426] h-full max-h-[525px] overflow-y-scroll p-2 scrollbar-thin">
                          <div
                            onClick={() =>
                              setOpenPaymentSummaryModal(
                                !openPaymentSummaryModal
                              )
                            }
                            className="fixed right-4 top-2 w-fit cursor-pointer rounded-full bg-[#558223] p-1.5 text-white"
                          >
                            <IoClose className="h-5 w-5" />
                          </div>
                          <PaymentCheckoutPopup
                            fetchedCreateInfo={fetchedCreateInfo}
                            setOpenSelectSlotModal={setOpenSelectSlotModal}
                            setOpenPaymentSummaryModal={
                              setOpenPaymentSummaryModal
                            }
                            setOpenPaymentSuccessfulModal={
                              setOpenPaymentSuccessfulModal
                            }
                            setOpenPurchaseSummaryModal={
                              setOpenPurchaseSummaryModal
                            }
                          />
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>

            <Transition appear show={openPaymentSuccessfulModal} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50"
                onClose={() => setOpenPaymentSuccessfulModal(false)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                        <div className="scrollbar-thumb-[#1a3517] scrollbar-track-[#265426] h-full max-h-[525px] overflow-y-scroll p-2 scrollbar-thin">
                          <div
                            onClick={() =>
                              setOpenPaymentSuccessfulModal(
                                !openPaymentSuccessfulModal
                              )
                            }
                            className="fixed right-4 top-2 w-fit cursor-pointer rounded-full bg-[#558223] p-1.5 text-white"
                          >
                            <IoClose className="h-5 w-5" />
                          </div>
                          <CreatedNewGropoolSuccessfullyPopup
                            fetchedCreateInfo={fetchedCreateInfo}
                          />
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>

            <Transition appear show={openRecipientDetailsModal} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50"
                onClose={() => setOpenRecipientDetailsModal(false)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-[#F9F9F9] text-left align-middle shadow-xl transition-all">
                        <div className="scrollbar-thumb-[#1a3517] scrollbar-track-[#265426] h-full max-h-[525px] overflow-y-scroll p-2 scrollbar-thin">
                          <div
                            onClick={() =>
                              setOpenRecipientDetailsModal(
                                !openRecipientDetailsModal
                              )
                            }
                            className="fixed right-4 top-2 z-40 w-fit cursor-pointer rounded-full bg-[#558223] p-1.5 text-white"
                          >
                            <IoClose className="h-5 w-5" />
                          </div>
                          <InputRecipientDetailsPopupGift
                            setFetchedCreateInfo={setFetchedCreateInfo}
                            fetchedCreateInfo={fetchedCreateInfo}
                            // fetchedGropoolProduct={fetchedGropoolProduct}
                            // totalSlotPurchasePrice={totalSlotPurchasePrice}
                            // count={count}
                            setOpenPurchaseSummaryModal={
                              setOpenPurchaseSummaryModal
                            }
                            setOpenRecipientDetailsModal={
                              setOpenRecipientDetailsModal
                            }
                            fetchedExistingAddress={fetchedExistingAddress}
                            setIsGift={setIsGift}
                            purchasingFor={purchasingFor}
                          />
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          </>
        </Container>
      </div>
    </>
  );
};

export default GropoolPage;
