import React, { useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import OtpInput from "react-otp-input";
import { CurrencyComponent } from "../../../../core/helpers";
import moment from "moment";
import { authService } from "../../../../services/authService";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  getAllProducts,
  handleCreateProduct,
  handleUpdateProduct,
} from "../../../../store/actions";
import { useNavigate } from "react-router-dom";

const PaymentCheckoutPopup = ({
  fetchedCreateInfo,
  setOpenPaymentSummaryModal,
  setOpenPaymentSuccessfulModal,
  setOpenPurchaseSummaryModal,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const userPhone = authService.getCurrentUserPhone();
  const customerId = authService.getCurrentUserCustomerId();

  console.log("fetchedCreateInfo", fetchedCreateInfo);

  const handleSubmit = () => {
    try {
      const toastId = toast.loading("Creating Product");

      const data = {
        gropool_date: fetchedCreateInfo?.data?.gropool_date,
        user_id: fetchedCreateInfo?.data?.user_id,
        slot_number: fetchedCreateInfo?.data?.slot_number,
        item_id: fetchedCreateInfo?.data?.item_id,
        item_price: fetchedCreateInfo?.data?.item_price,
        purchase_slot: fetchedCreateInfo?.data?.purchase_slot,
        sharing_name: fetchedCreateInfo?.data?.name,
        notification_status_id: fetchedCreateInfo?.data?.notification_status_id,
        recipient_ref_no: fetchedCreateInfo?.data?.recipient_ref_no
          ? fetchedCreateInfo?.data?.recipient_ref_no
          : "0",
        gift_status_id: fetchedCreateInfo?.data?.gift_status_id
          ? fetchedCreateInfo?.data?.gift_status_id
          : "2",
        pin_number: otp,
      };

      const payload = {
        customerId,
        data,
      };

      dispatch(handleCreateProduct(payload))
        .unwrap()
        .then((res) => {
          if (res.status === 403) {
            return toast.error(res?.message, { id: toastId });
          }
          dispatch(getAllProducts());
          setOpenPaymentSummaryModal(false);
          setOpenPaymentSuccessfulModal(true);
          return toast.success(res.message_text, { id: toastId });
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  const handleUpdateSubmit = () => {
    try {
      const toastId = toast.loading("Creating Product");

      const data = {
        gropool_date: fetchedCreateInfo?.data?.gropool_date,
        user_id: fetchedCreateInfo?.data?.user_id,
        slot_number: fetchedCreateInfo?.data?.slot_number,
        item_id: fetchedCreateInfo?.data?.item_id,
        item_price: fetchedCreateInfo?.data?.item_price,
        purchase_slot: fetchedCreateInfo?.data?.purchase_slot,
        sharing_name: fetchedCreateInfo?.data?.name,
        notification_status_id: fetchedCreateInfo?.data?.notification_status_id,
        recipient_ref_no: fetchedCreateInfo?.data?.recipient_ref_no
          ? fetchedCreateInfo?.data?.recipient_ref_no
          : "0",
        gift_status_id: fetchedCreateInfo?.data?.gift_status_id
          ? fetchedCreateInfo?.data?.gift_status_id
          : "2",
        pin_number: otp,
        ref_no: fetchedCreateInfo?.data?.ref_no,
        save_recipient_status_id:
          fetchedCreateInfo?.data?.save_recipient_status_id,
      };

      const payload = {
        customerId,
        data,
      };

      console.log("payload", payload);

      dispatch(handleUpdateProduct(payload))
        .unwrap()
        .then((res) => {
          if (res.status === 403) {
            return toast.error(res?.message, { id: toastId });
          }
          dispatch(getAllProducts());
          setOpenPaymentSummaryModal(false);
          setOpenPaymentSuccessfulModal(true);
          return toast.success(res.message_text, { id: toastId });
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  const goBack = () => {
    setOpenPaymentSummaryModal(false);
    setOpenPurchaseSummaryModal(true);
  };

  return (
    <div className="mb-6 px-2 sm:px-6">
      <h2 className="mt-6 text-xl font-bold text-gray-700">Pay With Wallet</h2>
      <div className="mt-6 rounded-md border-[1px] border-gray-200 bg-[#D5EBBB] p-4">
        <h4 className="text-xs font-medium text-gray-500">
          You are paying for:
        </h4>
        <p className="mt-2 text-xl font-bold text-gray-800">
          {fetchedCreateInfo && fetchedCreateInfo?.data?.item_price
            ? CurrencyComponent(fetchedCreateInfo?.data?.item_price)
            : null}
        </p>
        <p className="mt-1 text-sm font-normal text-gray-700">
          {`${
            fetchedCreateInfo && fetchedCreateInfo?.data?.purchase_slot
              ? fetchedCreateInfo?.data?.purchase_slot
              : null
          } slots of ${
            fetchedCreateInfo &&
            fetchedCreateInfo?.selectedItemObj?.name &&
            fetchedCreateInfo?.data?.slot_number
              ? `${fetchedCreateInfo?.selectedItemObj?.name.split(",")[0]}`
              : `${fetchedCreateInfo?.selectedItemObj?.item_name}`
          } share to be shared ${
            fetchedCreateInfo && fetchedCreateInfo?.data?.gropool_date
              ? moment(fetchedCreateInfo?.data?.gropool_date).format("LL")
              : null
          }`}
        </p>
        <p className="mt-2 text-base font-semibold text-gray-700">
          {userPhone ? userPhone : ""}
        </p>
      </div>
      <p className="mt-2 text-lg font-semibold text-gray-700">
        Please input your transaction PIN
      </p>
      <p
        onClick={() => navigate("/home/todo")}
        className="mt-1 flex cursor-pointer flex-row text-sm font-medium text-olivine-600 hover:underline hover:underline-offset-2"
      >
        Set-up Transaction pin
        <span className="cursor-pointer">
          <AiOutlineQuestionCircle className="ml-1 mt-1 h-4 w-4 text-olivine-600" />
        </span>
      </p>
      <div className="mt-1 flex justify-center">
        <OtpInput
          inputStyle={{
            margin: 2,
            height: 34,
            width: 34,
            borderRadius: 4,
            borderColor: "#BDBDBD",
            outline: "none",
            border: "1px solid #BDBDBD",
          }}
          numInputs={4}
          value={otp}
          onChange={setOtp}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      <div className="mt-8 flex items-center justify-between space-x-8 md:space-x-16">
        <button
          onClick={() => goBack()}
          className={`w-full transform rounded-md border-[1px] border-gray-300 px-10 py-4 text-xs font-semibold text-gray-600 transition-all duration-200 hover:bg-gray-100 focus:outline-none xsm:text-sm`}
        >
          Back
        </button>
        <button
          onClick={() =>
            fetchedCreateInfo?.isUpdate ? handleUpdateSubmit() : handleSubmit()
          }
          className={`w-full transform rounded-md px-10 py-4 text-xs font-semibold text-white transition-all duration-200 focus:outline-none xsm:text-sm ${
            otp?.length === 4
              ? "cursor-pointer bg-olivine-500 hover:bg-olivine-500/80 active:bg-olivine-600"
              : "cursor-disabled cursor-not-allowed bg-gray-300"
          }`}
          disabled={otp?.length < 4}
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default PaymentCheckoutPopup;
