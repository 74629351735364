import React, { useEffect, useState } from "react";
import GropoolNoteImage from "../../../../assets/gropoolNoteImage.svg";
import DatePicker from "react-datepicker";
import { MdDateRange } from "react-icons/md";
import { authService } from "../../../../services/authService";
import { CurrencyComponent } from "../../../../core/helpers";

const CreateNewGropool = ({
  products,
  setOpenGropoolModal,
  openGropoolModal,
  setOpenSelectSlotModal,
  setFetchedCreateInfo,
}) => {
  const [groupName, setGroupName] = useState("");
  const [selectedItem, setSelectedItem] = useState(
    (products &&
      products?.sharing_items?.length &&
      products?.sharing_items[0]?.sku) ||
      0
  );
  const [selectedSlotNumber, setSelectedSlotNumber] = useState(
    (products &&
      products?.sharing_slot?.length &&
      products?.sharing_slot[0]?.sharing_slot_id) ||
      0
  );
  const [slotPrice, setSlotPrice] = useState(0);
  const [slotToPurchase, setSlotToPurchase] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isNotifyChecked, setIsNotifyChecked] = useState(false);
  const [sharingDate, setSharingDate] = useState();

  const customerId = authService.getCurrentUserCustomerId();
  const userId = authService.getCurrentUserID();

  const today = new Date();
  const maxDate = new Date(today);
  maxDate.setDate(maxDate.getDate() + 10);

  useEffect(() => {
    if (
      selectedSlotNumber &&
      selectedItem &&
      products &&
      products?.sharing_items?.length
    ) {
      const selectedItemPrice = products?.sharing_items
        ?.filter((item) => item?.sku === selectedItem)
        .pop()?.item_rate_zoho;

      const selectedSlotPrice = selectedItemPrice / selectedSlotNumber;
      setSlotPrice(selectedSlotPrice);
    }
  }, [selectedItem, selectedSlotNumber]);

  const handleSubmit = async () => {
    try {
      const selectedItemObj = products?.sharing_items
        ?.filter((item) => item?.sku === selectedItem)
        .pop();

      const data = {
        gropool_date: new Date(sharingDate).toLocaleDateString("en-CA"),
        user_id: userId.toString(),
        slot_number: selectedSlotNumber.toString(),
        item_id: selectedItemObj?.item_id,
        item_price: slotPrice.toString(),
        price_per_slot: slotPrice.toString(),
        purchase_slot: slotToPurchase?.toString(),
        name: groupName,
        notification_status_id: isNotifyChecked ? "1" : "2",
      };

      const payload = {
        selectedItemObj,
        customerId,
        data,
      };

      setOpenSelectSlotModal(true);
      setOpenGropoolModal(!openGropoolModal);
      setFetchedCreateInfo(payload);
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="mb-20 px-2 sm:px-6">
      <h2 className="mt-6 text-xl font-semibold text-gray-700">
        Create new sharing group
      </h2>
      <div className="mt-6 rounded-md border-[1px] border-[#DE9512]/20 bg-[#FFF5E4] p-3">
        <div className="flex flex-shrink-0 items-center space-x-2">
          <div>
            <img src={GropoolNoteImage} alt="" className="h-5 w-5" />
          </div>
          <h3 className="text-base font-semibold text-[#DE9512]">
            Please Note
          </h3>
        </div>
        <p className="mt-1 text-sm font-normal text-gray-500">
          That By Creating a Group you are automatically Purchasing Slots for
          the Created group
        </p>
      </div>
      <div className="mt-4 rounded-lg border-[1px] border-[#DFE5D1] p-4 shadow-xl shadow-[#F6F6F5] sm:p-6">
        <form>
          <div>
            <label htmlFor="" className="ml-1 text-sm text-gray-500">
              Group Name
            </label>
            <div className="rounded-md">
              <input
                type="text"
                placeholder="Please input group name"
                onChange={(e) => setGroupName(e.target.value)}
                value={groupName}
                required
                className="mt-1 block w-full rounded-md border-gray-200 bg-white pl-4 text-base text-gray-700  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              />
            </div>
          </div>
          <div className="mt-4">
            <label for="fruits" className="ml-1 text-sm text-gray-500">
              Product Selection
            </label>
            <div className="rounded-md">
              <select
                onChange={(e) => setSelectedItem(e.target.value)}
                name="fruits"
                id="fruits"
                className="mt-1 block w-full rounded-md border-gray-200 bg-white pl-4 text-base text-gray-700  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              >
                {products && products?.sharing_items?.length
                  ? products?.sharing_items?.map((slot, idx) => (
                      <option key={idx} value={slot?.sku}>
                        {slot?.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>
          <div className="mt-4">
            <label for="fruits" className="ml-1 text-sm text-gray-500">
              Number of Slots
            </label>
            <div className="rounded-md">
              <select
                onChange={(e) => setSelectedSlotNumber(e.target.value)}
                name="fruits"
                id="fruits"
                className="mt-1 block w-full rounded-md border-gray-200 bg-white pl-4 text-base text-gray-700  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              >
                {products && products?.sharing_slot?.length
                  ? products?.sharing_slot?.map((slot, idx) => (
                      <option key={idx} value={slot?.sharing_slot_id}>
                        {slot?.sharing_slot_name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="" className="ml-1 text-sm text-gray-500">
              Slot Price(Automatically Generated)
            </label>
            <div className="rounded-md">
              <input
                type="text"
                placeholder="Prefilled"
                required
                value={CurrencyComponent(slotPrice)}
                readOnly
                className="mt-1 block w-full rounded-md border-gray-200 bg-white pl-4 text-base text-gray-700  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              />
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="" className="ml-1 text-sm text-gray-500">
              Product Sharing Date
            </label>
            <div className="relative rounded-md">
              <DatePicker
                selected={sharingDate}
                onChange={(date) => setSharingDate(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText="Sharing Date"
                minDate={today}
                maxDate={maxDate}
                className="h-10 w-[100%] rounded-md border-gray-400 bg-white text-sm text-gray-600 focus:border-olivine-500 focus:ring-olivine-500 xsm:w-full"
              />
              <MdDateRange className="absolute right-1 top-2 h-6 w-6 text-olivine-500" />
            </div>
          </div>
          <div className="mt-4">
            <label for="fruits" className="ml-1 text-sm text-gray-500">
              How many Slots are you puchasing?
            </label>
            <div className="rounded-md">
              <select
                onChange={(e) => setSlotToPurchase(e.target.value)}
                name="no_of_slots"
                id="fruits"
                className="mt-1 block w-full rounded-md border-gray-200 bg-white pl-4 text-base text-gray-700  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
              >
                {Array.from({ length: selectedSlotNumber }, (_, index) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </div>
      <div className="mt-5">
        <div className="flex flex-row items-center">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            className="h-5 w-5 cursor-pointer rounded border-[1px] border-gray-300 checked:text-[#558223] checked:ring-0 checked:ring-[#558223] focus:ring-0 focus:ring-[#558223]"
          />
          <span className="ml-2 text-sm  text-gray-600">
            I agree with the{" "}
            <span className="cursor-pointer font-medium text-[#558223] hover:underline hover:underline-offset-4">
              Terms and Conditions
            </span>
          </span>
        </div>
        <div className="mt-3 flex flex-row items-center">
          <input
            type="checkbox"
            checked={isNotifyChecked}
            onChange={(e) => setIsNotifyChecked(e.target.checked)}
            className="h-5 w-5 cursor-pointer rounded border-[1px] border-gray-300 checked:text-[#558223] checked:ring-0 checked:ring-[#558223] focus:ring-0 focus:ring-[#558223]"
          />
          <span className="ml-2 text-sm text-gray-600">
            Notify me when someone joins my Groups
          </span>
        </div>
      </div>
      <div className="mx-auto mt-8 max-w-sm">
        <button
          onClick={() => handleSubmit()}
          className={`w-full transform rounded-md px-10 py-4 text-xs font-semibold text-white transition-all duration-200 focus:outline-none xsm:text-sm ${
            isChecked && groupName
              ? "cursor-pointer bg-olivine-500 hover:bg-olivine-500/80 active:bg-olivine-600"
              : "cursor-disabled cursor-not-allowed bg-gray-400"
          }`}
          disabled={!isChecked && !groupName}
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default CreateNewGropool;
