import React, { useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../../../services/authService";
import { addNewReceipient, fetchCountries } from "../../../store/actions";
import toast from "react-hot-toast";
import {
  addressDescriptionValidator,
  giftDescriptionValidator,
} from "../../../core/helpers";

const InputRecipientDetailsPopup = ({
  setFetchedCreateInfo,
  fetchedGropoolProduct,
  totalSlotPurchasePrice,
  count,
  setOpenPurchaseSummaryModal,
  setOpenRecipientDetailsModal,
  fetchedExistingAddress,
  setIsGift,
  purchasingFor,
}) => {
  const dispatch = useDispatch();
  const { fetchCountriesData } = useSelector((state) => state.masterData);
  const { giftDelivery } = useSelector((state) => state.modal);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
  });
  const [errorMsg, setErrorMsg] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    selectedAreaId: "",
    addr1: "",
    phone: "",
  });
  const [isNotifyChecked, setIsNotifyChecked] = useState(false);

  const [valid, setValid] = useState(false);
  const [fetchedCountries, setFetchedCountries] = useState();
  const [countries, setCountries] = useState();
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedAreaId, setSelectedAreaId] = useState(
    (giftDelivery && giftDelivery?.selectedAreaId) || ""
  );
  // const [selectedAreaName, setSelectedAreaName] = useState("");
  const [addr1, setAddr1] = useState(
    (giftDelivery && giftDelivery?.addr1) || ""
  );
  const [addr2, setAddr2] = useState(
    (giftDelivery && giftDelivery?.addr2) || ""
  );
  const [landmark, setLandmark] = useState(
    (giftDelivery && giftDelivery?.landmark) || ""
  );
  const [phone, setPhone] = useState(giftDelivery && giftDelivery?.phone);
  const [zipcode, setZipcode] = useState(
    (giftDelivery && giftDelivery?.zipcode) || ""
  );
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedAddr, setSelectedAddr] = useState();

  // Address 1
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: "ng" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      setAddr1(place?.name);
    });
  }, [options]);

  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();

  useEffect(() => {
    if (!fetchCountriesData) {
      const data = {
        sql_ref_no: "12",
        user_id: userId?.toString(),
        c_code: verification_code,
        option: "19",
      };

      dispatch(fetchCountries(data));
    }

    if (fetchCountriesData)
      setFetchedCountries(fetchCountriesData.location_info);
  }, [fetchCountriesData, userId, verification_code, dispatch]);

  useEffect(() => {
    if (fetchedCountries) {
      const resArr = [];
      fetchedCountries.filter((item) => {
        let i = resArr.findIndex((x) => x === item.country_name);
        if (i <= -1) {
          resArr.push(item.country_name);
        }
        return null;
      });

      setCountries(resArr);
    }
  }, [fetchedCountries]);

  useEffect(() => {
    if (selectedAreaId && addr1 && addr2 && landmark && zipcode) setValid(true);
  }, [selectedAreaId, addr1, addr2, landmark, zipcode]);

  const getCities = (count) => {
    const cities = [];
    fetchedCountries.filter((item) => {
      if (item.country_name === count) {
        cities.push(item);
      }
      return null;
    });

    const resArr = [];
    cities.filter((item) => {
      let i = resArr.findIndex((x) => x === item.city_name);
      if (i <= -1) {
        resArr.push(item.city_name);
      }
      return null;
    });

    return resArr;
  };

  const getAreas = (cit) => {
    const areas = [];
    fetchedCountries.filter((item) => {
      if (item.city_name === cit) {
        areas.push(item);
      }
      return null;
    });

    return areas;
  };

  const handleChangeEvent = (e) => {
    setPhone(e.target.value.replace(/[^0-9]/g, ""));
  };

  const handleInputChange = (event) => {
    try {
      const name = event.target.name;
      const value = event.target.value;
      setFormValues((values) => ({ ...values, [name]: value }));
    } catch (err) {
      return err;
    }
  };

  const handleChange = (event) => {
    setSelectedCountry(event.target.value);
    const selectedCities = getCities(event.target.value);
    setCities(selectedCities);
  };

  const handleChangeArea = (event) => {
    setSelectedState(event.target.value);
    const selectedAreas = getAreas(event.target.value);

    setAreas(selectedAreas);
  };

  const handleChangeAreaID = (event) => {
    setSelectedAreaId(event.target.value.split("-")[0]);
    // setSelectedAreaName(event.target.value.split("-")[1]);
  };

  const payloadValuesError = giftDescriptionValidator(
    formValues?.first_name,
    formValues?.last_name,
    formValues?.phone_number,
    formValues?.email_address
  );

  const payloadValuesAddrError = addressDescriptionValidator(
    addr1,
    phone,
    selectedAreaId
  );

  const handleProceed = () => {
    try {
      if (
        Object.keys(payloadValuesError).length ||
        Object.keys(payloadValuesAddrError).length
      ) {
        toast.error("Please Fill required fields ...");
        return setErrorMsg({
          ...errorMsg,
          first_name: payloadValuesError.first_name,
          last_name: payloadValuesError.last_name,
          phone_number: payloadValuesError.phone_number,
          email_address: payloadValuesError.email_address,
          addr1: payloadValuesAddrError.addr1,
          phone: payloadValuesAddrError.phone,
          selectedAreaId: payloadValuesAddrError.areaId,
        });
      }

      const toastId = toast.loading("Submitting information...");
      const data = {
        ...formValues,
        shipping_area_id: selectedAreaId,
        address_line_one: addr1,
        address_line_two: addr2,
        landmark,
        zip_code: zipcode,
        shipping_phone_number: phone,
        user_id: userId.toString(),
        save_recipient_status_id: isNotifyChecked ? "1" : "2",
      };

      const payload = {
        customerId,
        data,
      };

      dispatch(addNewReceipient(payload))
        .unwrap()
        .then((res) => {
          if (res?.message_id != 1) {
            return toast.error(res?.message_text, { id: toastId });
          }

          toast.success(res?.message_text, { id: toastId });

          handleNextPhase(
            fetchedGropoolProduct?.length && fetchedGropoolProduct[0],
            res?.message_ref_no
          );
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  const handleNextPhase = (selectedProduct, ref_no) => {
    try {
      var data = {
        gropool_date: new Date(
          selectedProduct?.purchase_date
        ).toLocaleDateString("en-CA"),
        user_id: userId.toString(),
        slot_number: selectedProduct?.total_remaining_slot.toString(),
        item_id: selectedProduct?.item_id,
        item_price: totalSlotPurchasePrice.toString(),
        price_per_slot: selectedProduct?.slot_price.toString(),
        purchase_slot: count?.toString(),
        name: selectedProduct?.item_name,
        notification_status_id: "1",
        gift_status_id: "1",
        recipient_ref_no: ref_no.toString(),
        ref_no: selectedProduct?.ref_no.toString(),
      };

      var payload = {
        selectedItemObj: selectedProduct,
        customerId,
        data,
        purchasingFor,
        isUpdate: true,
      };

      setFetchedCreateInfo(payload);
      setOpenPurchaseSummaryModal(true);
      setOpenRecipientDetailsModal(false);
      setIsGift(true);
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="relative z-20 mb-28 px-2 @container sm:px-6">
      <div>
        <div className="mt-10">
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            {/*   Tab section header */}
            <Tab.List className="flex w-full flex-col items-center space-x-1 space-y-2 rounded-lg border-[1px] border-gray-200 bg-white px-4 py-2 text-sm @md:flex-row @md:space-y-0">
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={
                      selected
                        ? "w-full rounded border-[1px] border-gray-100 border-opacity-100 bg-gray-100 px-4 py-1 text-olivine-600 outline-none"
                        : "w-full rounded border-[1px] border-gray-100 border-opacity-0 px-4 py-1 text-gray-500 hover:bg-[#F9F9F9]"
                    }
                  >
                    <p className="cursor-pointer py-2 font-medium">
                      Input Recipient Details
                    </p>
                  </button>
                )}
              </Tab>

              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={
                      selected
                        ? "w-full rounded border-[1px] border-gray-100 border-opacity-100 bg-gray-100 px-4 py-1 text-olivine-600 outline-none"
                        : "w-full rounded border-[1px] border-gray-100 border-opacity-0 px-4 py-1 text-gray-500 hover:bg-[#F9F9F9]"
                    }
                  >
                    <p className="cursor-pointer py-2 font-medium">
                      Saved Delivery Details
                    </p>
                  </button>
                )}
              </Tab>
            </Tab.List>

            <Tab.Panels>
              <Tab.Panel>
                <div>
                  <h2 className="mt-6 text-xl font-bold text-gray-700">
                    Input Recipient’s Details
                  </h2>
                  <p className="mt-1 text-sm font-normal text-gray-600">
                    Please input your gift recipient’s delivery address
                  </p>
                  <div className="flex flex-col @sm:flex-row @sm:items-center @sm:gap-3">
                    <div className="mt-4 flex-1">
                      <label
                        htmlFor="firstName"
                        className="ml-1 text-sm text-gray-500"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        value={formValues.first_name}
                        onChange={handleInputChange}
                        placeholder="Input recipient’s first name"
                        className="mt-[2px] block h-10 w-full rounded-md border-gray-200 bg-white text-sm text-gray-600  placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                      {errorMsg?.first_name ? (
                        <span className="ml-3 text-xs text-red-500">
                          {errorMsg?.first_name}
                        </span>
                      ) : null}
                    </div>
                    <div className="mt-4 flex-1">
                      <label
                        htmlFor="lastName"
                        className="ml-1 text-sm text-gray-500"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        value={formValues.last_name}
                        onChange={handleInputChange}
                        placeholder="Input recipient’s last name"
                        className="mt-[2px] block h-10 w-full rounded-md border-gray-200 bg-white text-sm text-gray-600  placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                      {errorMsg?.last_name ? (
                        <span className="ml-3 text-xs text-red-500">
                          {errorMsg?.last_name}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="emailAddress"
                      className="ml-1 text-sm text-gray-500"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      name="email_address"
                      value={formValues.email_address}
                      onChange={handleInputChange}
                      placeholder="Input recipient’s email address"
                      className="mt-[2px] block h-10 w-full rounded-md border-gray-200 bg-white text-sm text-gray-600  placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    />
                    {errorMsg?.email_address ? (
                      <span className="ml-3 text-xs text-red-500">
                        {errorMsg?.email_address}
                      </span>
                    ) : null}
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="phoneNumber"
                      className="ml-1 text-sm text-gray-500"
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="phone_number"
                      value={formValues.phone_number}
                      onChange={handleInputChange}
                      placeholder="Input recipient’s phone number"
                      className="mt-[2px] block h-10 w-full rounded-md border-gray-200 bg-white text-sm text-gray-600  placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    />
                    {errorMsg?.phone_number ? (
                      <span className="ml-3 text-xs text-red-500">
                        {errorMsg?.phone_number}
                      </span>
                    ) : null}
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="countryRegion"
                      className="ml-1 text-sm text-gray-500"
                    >
                      Country / Region
                    </label>
                    <select
                      onChange={handleChange}
                      className="mt-[2px] block w-full rounded-md border-gray-200 bg-white pl-4 text-base text-gray-700  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    >
                      {giftDelivery &&
                      giftDelivery?.country &&
                      giftDelivery?.country ? (
                        <option>{giftDelivery?.country}</option>
                      ) : (
                        <option>select</option>
                      )}
                      {countries &&
                        countries.map((country, idx) => (
                          <option key={idx} value={country}>
                            {country}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="flex flex-col @sm:flex-row @sm:items-center @sm:gap-3">
                    <div className="mt-4 flex-1">
                      <label
                        htmlFor="state"
                        className="ml-1 text-sm text-gray-500"
                      >
                        State
                      </label>
                      <select
                        onChange={handleChangeArea}
                        className="mt-[2px] block w-full rounded-md border-gray-200 bg-white pl-4 text-base text-gray-700  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      >
                        {giftDelivery &&
                        giftDelivery?.city &&
                        giftDelivery?.city ? (
                          <option>{giftDelivery?.city}</option>
                        ) : (
                          <option>select</option>
                        )}
                        {cities &&
                          cities.map((city, idx) => (
                            <option key={idx} value={city}>
                              {city}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mt-4 flex-1">
                      <label
                        htmlFor="city"
                        className="ml-1 text-sm text-gray-500"
                      >
                        Area
                      </label>
                      <select
                        onChange={handleChangeAreaID}
                        className="mt-[2px] block w-full rounded-md border-gray-200 bg-white pl-4 text-base text-gray-700  placeholder:text-sm placeholder:font-normal placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      >
                        {giftDelivery &&
                        giftDelivery?.selectedAreaName &&
                        giftDelivery?.selectedAreaName ? (
                          <option>{giftDelivery?.selectedAreaName}</option>
                        ) : (
                          <option>select</option>
                        )}
                        {areas &&
                          areas.map((area, idx) => (
                            <option
                              key={idx}
                              value={`${area.area_id}-${area.area_name}`}
                            >
                              {area.area_name}
                            </option>
                          ))}
                      </select>

                      {errorMsg?.selectedAreaId ? (
                        <span className="ml-3 text-xs text-red-500">
                          {errorMsg?.selectedAreaId}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="addressLine1"
                      className="ml-1 text-sm text-gray-500"
                    >
                      Address Line 1
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        const limit = 50;

                        // 👇️ only take first N characters
                        setAddr1(e.target.value.slice(0, limit));
                      }}
                      value={addr1}
                      ref={inputRef}
                      placeholder="House/Flat No, Street/Road name"
                      className="mt-[2px] block h-10 w-full rounded-md border-gray-200 bg-white text-sm text-gray-600  placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    />

                    {errorMsg?.addr1 ? (
                      <span className="ml-3 text-xs text-red-500">
                        {errorMsg?.addr1}
                      </span>
                    ) : null}
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="addressLine2"
                      className="ml-1 text-sm text-gray-500"
                    >
                      Address Line 2
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        const limit = 50;

                        // 👇️ only take first N characters
                        setAddr2(e.target.value.slice(0, limit));
                      }}
                      value={addr2}
                      placeholder=""
                      className="mt-[2px] block h-10 w-full rounded-md border-gray-200 bg-white text-sm text-gray-600  placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                    />
                  </div>
                  <div className="flex flex-col @sm:flex-row @sm:items-center @sm:gap-3">
                    <div className="mt-4 flex-1">
                      <label
                        htmlFor="landmark"
                        className="ml-1 text-sm text-gray-500"
                      >
                        Landmark
                      </label>
                      <input
                        type="text"
                        onChange={(e) => {
                          const limit = 50;

                          // 👇️ only take first N characters
                          setLandmark(e.target.value.slice(0, limit));
                        }}
                        value={landmark}
                        placeholder="landmark"
                        className="mt-[2px] block h-10 w-full rounded-md border-gray-200 bg-white text-sm text-gray-600  placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                    <div className="mt-4 flex-1">
                      <label
                        htmlFor="zipCode"
                        className="ml-1 text-sm text-gray-500"
                      >
                        Zip Code
                      </label>
                      <input
                        type="text"
                        onChange={(e) => {
                          const limit = 6;

                          // 👇️ only take first N characters
                          setZipcode(
                            e.target.value
                              .replace(/[^0-9]/g, "")
                              .slice(0, limit)
                          );
                        }}
                        value={zipcode}
                        placeholder="Input Zip Code"
                        className="mt-[2px] block h-10 w-full rounded-md border-gray-200 bg-white text-sm text-gray-600  placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                    <div className="mt-4">
                      <label
                        htmlFor="phoneNumber"
                        className="ml-1 text-sm text-gray-500"
                      >
                        Phone Number
                      </label>
                      <input
                        type="text"
                        onChange={handleChangeEvent}
                        value={phone}
                        placeholder="Input recipient’s phone number"
                        className="mt-[2px] block h-10 w-full rounded-md border-gray-200 bg-white text-sm text-gray-600  placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                      {errorMsg?.phone ? (
                        <span className="ml-3 text-xs text-red-500">
                          {errorMsg?.phone}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-4 flex flex-row items-center">
                    <input
                      type="checkbox"
                      checked={isNotifyChecked}
                      onChange={(e) => setIsNotifyChecked(e.target.checked)}
                      className="h-5 w-5 cursor-pointer rounded border-[1px] border-gray-300 checked:text-[#558223] checked:ring-0 checked:ring-[#558223] focus:ring-0 focus:ring-[#558223]"
                    />
                    <span className="ml-2 text-sm  text-gray-600">
                      Save Delivery Information/Address
                    </span>
                  </div>
                </div>
              </Tab.Panel>

              <Tab.Panel>
                <div>
                  <h2 className="mt-6 text-xl font-semibold text-gray-700">
                    Saved Delivery Address
                  </h2>
                  <p className="mt-1 text-sm font-normal text-gray-600">
                    Please select a delivery address from your saved options
                  </p>
                  <div className="mt-4 divide-y-[1px] divide-gray-200">
                    {fetchedExistingAddress && fetchedExistingAddress?.length
                      ? fetchedExistingAddress.map((addr, idx) => (
                          <div
                            key={idx}
                            className="cursor-pointer py-2"
                            onClick={() =>
                              setSelectedAddr(addr?.recipient_ref_no)
                            }
                          >
                            <div className="flex flex-row items-center justify-between">
                              <p className="text-sm font-semibold text-gray-800">
                                Saved Option
                              </p>

                              {selectedAddr === addr?.recipient_ref_no ? (
                                <IoCheckmarkCircle className="h-4 w-4 text-olivine-600" />
                              ) : null}
                            </div>
                            <p className="mt-1 text-xs text-gray-600">
                              {addr?.recipient_full_name}
                            </p>
                            <p className="mt-1 text-xs text-gray-600">
                              {addr?.recipient_phone_number}
                            </p>
                            <p className="mt-1 text-xs text-gray-600">
                              {addr?.recipient_email_address}
                            </p>
                            <p className="mt-1 text-xs text-gray-600">
                              {addr?.recipient_full_address_shipping}
                            </p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </Tab.Panel>
              <div className="fixed bottom-3 right-4 flex items-center space-x-3 sm:space-x-4">
                <button
                  onClick={() => setOpenRecipientDetailsModal(false)}
                  className="w-full transform cursor-pointer rounded-md border-[1px] border-[#558223] bg-white px-8 py-3 text-sm font-semibold text-[#558223] transition-all duration-200 focus:outline-none @sm:max-w-[200px]"
                >
                  Back
                </button>
                <button
                  onClick={() =>
                    selectedIndex === 0
                      ? handleProceed()
                      : handleNextPhase(
                          fetchedGropoolProduct?.length &&
                            fetchedGropoolProduct[0],
                          selectedAddr
                        )
                  }
                  className="w-full transform cursor-pointer rounded-md bg-[#558223] px-6 py-3 text-sm font-semibold text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 @sm:mt-0 @sm:max-w-[200px]"
                >
                  Proceed
                </button>
              </div>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
};

export default InputRecipientDetailsPopup;
