import { createSlice } from "@reduxjs/toolkit";
import {
  addNewReceipient,
  fetchFAQ,
  fetchHowItWorks,
  fetchProducts,
  getAllGiftReceipients,
  getAllProducts,
  getProductShareHistory,
  getProductShareHistorySingle,
  getSingleGiftReceipient,
  getSingleProduct,
  handleCreateProduct,
  handleUpdateProduct,
  updateReceipient,
} from "../actions";

let initialState = {
  productSharing: [],
  faq: [],
  products: {},
  createProduct: {},
  updatedProduct: {},
  allProducts: [],
  singleProduct: {},
  newReceipientAdd: {},
  receipientUpdate: {},
  allGiftReceipients: [],
  singleGiftReceipient: {},
  history: [],
  historySingle: {},
};

const ProductSharingSlice = createSlice({
  name: "ProductSharingData",
  initialState,
  reducers: {
    resetProductShare(state) {
      initialState = {};
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchHowItWorks.fulfilled, (state, { payload }) => {
      state.productSharing = payload;
    });

    builder.addCase(fetchFAQ.fulfilled, (state, { payload }) => {
      state.faq = payload;
    });

    builder.addCase(fetchProducts.fulfilled, (state, { payload }) => {
      state.products = payload;
    });

    builder.addCase(handleCreateProduct.fulfilled, (state, { payload }) => {
      state.createProduct = payload;
    });

    builder.addCase(handleUpdateProduct.fulfilled, (state, { payload }) => {
      state.updatedProduct = payload;
    });

    builder.addCase(getAllProducts.fulfilled, (state, { payload }) => {
      state.allProducts = payload;
    });

    builder.addCase(getSingleProduct.fulfilled, (state, { payload }) => {
      state.singleProduct = payload;
    });

    builder.addCase(addNewReceipient.fulfilled, (state, { payload }) => {
      state.newReceipientAdd = payload;
    });

    builder.addCase(updateReceipient.fulfilled, (state, { payload }) => {
      state.receipientUpdate = payload;
    });

    builder.addCase(getAllGiftReceipients.fulfilled, (state, { payload }) => {
      state.allGiftReceipients = payload;
    });

    builder.addCase(getSingleGiftReceipient.fulfilled, (state, { payload }) => {
      state.singleGiftReceipient = payload;
    });

    builder.addCase(getProductShareHistory.fulfilled, (state, { payload }) => {
      state.history = payload;
    });

    builder.addCase(
      getProductShareHistorySingle.fulfilled,
      (state, { payload }) => {
        state.historySingle = payload;
      }
    );
  },
});

const { reducer } = ProductSharingSlice;

export default reducer;
